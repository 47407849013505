<script setup lang="ts">
import { reactiveComputed } from '@vueuse/core'
import dayjs from 'dayjs'
import { useAntdForm } from '@peng_kai/kit/antd'
import ModalContainer from './ModalContainer.vue'
import { getPayAmountByResource } from '~/composables/app'
import { useRentDuration } from '~/composables/tron'
import MoreDuration from '~/components/more-duration/index'
import useHideFormHelp from '~/composables/useHideFormHelp'
import BigNumber, { default as bignumber } from 'bignumber.js';

interface CalculationParams {
  resourceValue: number
  rentDuration: number
  rentTimeUnit: string
}
interface CalculationResult {
  amount: BigNumber;
  originalPrice: BigNumber
  c2cOriginal: BigNumber
  c2cDiscount: BigNumber
}

const props = defineProps<{
  setType?: string
  editRow?: TDetail
  successFunc?: () => void
}>()

const emits = defineEmits<{
  (e: 'update:modelValue', value: boolean): void
  (e: 'update:editRow', value: TDetail | null | undefined): void
  (e: 'update:setType', value: 'add' | 'edit'): void
}>()

type TDetail = NonNullable<Api.GetData<typeof apis.cronAddressList>>[number]
const dateUtil = dayjs

// 检查登录状态start
const { config } = useAppState()
const getIsAddRef = computed(() => props.setType === 'add')
const getResourceTypeTextRef = computed(() => ({
  capital: buyForm.state.resource_type
    ? $t('Mqe_hlD_yGPsAMB9oaO1D')
    : $t('xo7kGD9R2fV50wOIihlhL'),
  lowerCase: buyForm.state.resource_type
    ? $t('BfszzD-c8U_TaduQErz6H')
    : $t('K4u8gHuxKMkAlzEWaiWw2'),
}))

const { account: tronAccount } = tronComp.useAccountCurrent()
const $rangePickerContainer = ref<HTMLDivElement>()

const showPriceInSun = computed(() => buyForm.state.order_type === 2)
const btnLoading = ref(false)
const submitModalConfirmRef = ref()
const stopWatchAddress = watch(() => tronAccount.address, (abb) => {
  if (abb) {
    buyForm.state.wallet_address = abb
    setTimeout(() => stopWatchAddress())
  }
}, { immediate: false })

// 租用时长
function moreDurationChange(data: { dur: number; unit: string }) {
  rentDurationMap.duration = data.dur
  rentDurationMap.unit = data.unit
  buyForm.state.duration = data.dur
  buyForm.state.rentTimeUnit = data.unit
  buyForm.$form.value?.clearValidate()
}
const checkedValues = reactive({
  checkPriceInSun: true,
  checkEffectiveTime: true,
})
const computeShowFooterInput = computed(() => ({
  showPriceInSunInput: showPriceInSun.value && !checkedValues.checkPriceInSun,
  showEffectiveTimeInput: !checkedValues.checkEffectiveTime,
  showAmountLimitInput: buyForm.state.limit_type === 1,
  showCountLimitInput: buyForm.state.limit_type === 2,
}))

const rentDurationMap = useRentDuration()

// 数据定义/校验
const buyForm = useAntdForm({
  resource_type: {
    value: 1,
  },
  wallet_address: {
    value: tronAccount.address,
    rules: [{
      validator(rule, value) {
        if (!value)
          return promiseError($t('lexKD2K3V3JZO4PUzi9sW', { type: getResourceTypeTextRef.lowerCase }))

        return Promise.resolve()
      },
    }],
  },
  resource_threshold: {
    value: ref(),
    rules: [{
      validator(rule, value) {
        if (value === '' || Number.isNaN(+value))
          return Promise.reject($t('W692s6VNMbeZNfaahszFU'))

        if (+value <= 0)
          return Promise.reject($t('C6zRHz21lr_QgX64cU4Hs'))

        return Promise.resolve()
      },
    }],
  },
  order_resource_value: {
    value: ref(),
    rules: [{
      validator(rule, value) {
        if (value === '' || Number.isNaN(+value))
          return Promise.reject($t('WB8bPzpESFn2Syjva5vOs'))

        if (+value <= 0)
          return Promise.reject($t('sYqqcTqZ1luQHyBlfRzK7'))

        return Promise.resolve()
      },
    }],
  },
  rentDuration: {
    value: 1,
    rules: [{
      validator(rule, value) {
        if (typeof value !== 'number')
          return Promise.reject($t('ojnO7gOn5bBgUVpOzx3xy'))

        return Promise.resolve()
      },
    }],
  },
  rentTimeUnit: {
    value: toRef(rentDurationMap, 'unit'),
  },
  rentTimeSecond: {
    value: ref(3600),
    rules: [{
      validator(rule, value) {
        if (value <= 0)
          return promiseError($t('dHgidzfd5qiruU6fTpsQ'))

        return Promise.resolve()
      },
    }],
  },
  order_type: { value: ref(1) },
  status: { value: ref(2) },
  price_in_sun: {
    value: ref(),
    rules: [{
      validator(rule, value) {
        if (!computeShowFooterInput.value.showPriceInSunInput)
          return Promise.resolve()

        if (value === '' || Number.isNaN(+value))
          return Promise.reject($t('jfkyK2uAMax1WH3sF3nb5'))

        if (+value <= 0)
          return Promise.reject($t('j6g5JsmtGxAs1oku7kI0f'))

        if (value < priceRange.c2cMinSun)
          return promiseError(`${$t('AViSoCr8xENOqhGL66Iqa')}  ${priceRange.c2cMinSun} sun！`)

        return Promise.resolve()
      },
    }],
  },
  limit_type: { value: ref(0) },
  amount_limit: {
    value: ref(),
    rules: [{
      validator(rule, value) {
        if (!computeShowFooterInput.value.showAmountLimitInput)
          return Promise.resolve()

        if (value === '' || Number.isNaN(+value))
          return Promise.reject($t('YnyjWbeTvyOIi06VoAf9K'))

        if (+value <= 0)
          return Promise.reject($t('TGJNwXit86GBsUbJgXBMs'))

        return Promise.resolve()
      },
    }],
  },
  count_limit: {
    value: ref(),
    rules: [{
      validator(rule, value) {
        if (!computeShowFooterInput.value.showCountLimitInput)
          return Promise.resolve()

        if (value === '' || Number.isNaN(+value))
          return Promise.reject($t('Hl_OiufUuUb13jS9NSZRZ'))

        if (+value <= 0)
          return Promise.reject($t('V0Z4g7wwD9YluZ2w-LuiS'))

        return Promise.resolve()
      },
    }],
  },
  effectiveTime: {
    value: ref(),
    rules: [{
      validator(rule, value) {
        if (!computeShowFooterInput.value.showEffectiveTimeInput)
          return Promise.resolve()

        if (!value || value?.length !== 2)
          return Promise.reject($t('saA0lUcXQjYfPZpGn3wFB'))

        return Promise.resolve()
      },
    }],
  },
})
const { target, checking } = useHideFormHelp(buyForm.$form)
function changeResourceType() {
  if (!buyForm.state.resource_type) {
    buyForm.state.order_type = 2
  }
  else {
    if (!checkedValues.checkPriceInSun && buyForm.state.price_in_sun)
      buyForm.state.order_type = 2

    else
      buyForm.state.order_type = 1
  }
  // clearValidate()
  // // 若有自定义单价则手动触发验证
  // if (!checkedValues.checkPriceInSun && buyForm.state.price_in_sun)
  //   validateField('price_in_sun', buyForm.state.price_in_sun, buyForm.itemProps.price_in_sun)
}

const resourceType = computed(() =>
  buyForm.state.resource_type ? 'ENERGY' : 'BANDWIDTH',
)
const priceRange = reactiveComputed(() => {
  const type = resourceType.value.toLowerCase()
  const range = {
    c2cMinSun: config.value?.price_c2c[type]!.sun ?? 90, // 最小值
    c2cMaxSun: 0,
  }
  // @ts-expect-error
  const source = config.value?.price_c2c[type]
  if (!source)
    return range

  if (buyForm.state.rentTimeUnit === 'd') {
    if (buyForm.state.rentDuration === 1)
      range.c2cMinSun = source.sun_1d || 0
  }
  else if (buyForm.state.rentTimeUnit === 'h') {
    if (buyForm.state.rentDuration === 1)
      range.c2cMinSun = source.sun_1h || 0
    else if (buyForm.state.rentDuration === 6)
      range.c2cMinSun = source.sun_6h || 0
    else if (buyForm.state.rentDuration === 3)
      range.c2cMinSun = source.sun_3h || 0
  }

  range.c2cMaxSun = source?.max_sun

  return range
})

onMounted(() => {
  const { editRow } = props
  if (!editRow)
    return

  // 编辑数据处理
  if (!getIsAddRef.value && editRow) {
    const {
      resource_type,
      wallet_address,
      resource_threshold,
      order_resource_value,
      rent_time_second,
      price_in_sun,
      start_time,
      end_time,
      amount_limit,
      order_type,
      status,
      limit_type,
      count_limit,
      rent_duration,
      rent_time_unit,
    } = editRow

    buyForm.state.resource_type = ~~resource_type
    buyForm.state.order_type = buyForm.state.resource_type ? ~~order_type : 2
    buyForm.state.status = ~~status
    buyForm.state.limit_type = ~~limit_type
    buyForm.state.wallet_address = wallet_address ?? ''
    // buyForm.state.resource_threshold = resource_threshold ? String(resource_threshold) : ''
    // buyForm.state.order_resource_value = order_resource_value ? String(order_resource_value) : ''
    buyForm.state.resource_threshold = resource_threshold || ''
    buyForm.state.order_resource_value = order_resource_value || ''
    // 编辑租用时间处理
    // rentDurationMap.unit,
    buyForm.state.rentDuration = rent_duration
    buyForm.state.rentTimeSecond = rent_time_second
    rentDurationMap.unit = rent_time_unit
    rentDurationMap.duration = rent_duration

    buyForm.state.price_in_sun = price_in_sun ? String(price_in_sun) : ''

    if (price_in_sun && showPriceInSun.value)
      checkedValues.checkPriceInSun = false

    // 编辑有效时间处理
    if (start_time && end_time) {
      buyForm.state.effectiveTime = [dateUtil(start_time * 1000), dateUtil(end_time * 1000)]
      checkedValues.checkEffectiveTime = false
    }

    buyForm.state.amount_limit = amount_limit ? String(amount_limit) : ''

    buyForm.state.count_limit = count_limit ? String(count_limit) : ''
  }
})

// 请求参数处理
function formDataToRequestBody() {
  const requestBody = {
    resource_type: ~~buyForm.state.resource_type,
    wallet_address: buyForm.state.wallet_address,
    resource_threshold: Number.parseInt(buyForm.state.resource_threshold, 10),
    order_resource_value: Number.parseInt(buyForm.state.order_resource_value, 10),
    rent_time_second: buyForm.state.rentTimeSecond,
    price_in_sun: 0,
    start_time: 0,
    end_time: 0,
    amount_limit: 0,
    order_type: ~~buyForm.state.order_type,
    status: 2,
    limit_type: ~~buyForm.state.limit_type,
    count_limit: 0,
  }

  if (!requestBody.resource_type)
    requestBody.order_type = 2

  if (computeShowFooterInput.value.showPriceInSunInput)
    requestBody.price_in_sun = Number.parseInt(buyForm.state.price_in_sun, 10)

  if (computeShowFooterInput.value.showEffectiveTimeInput && buyForm.state.effectiveTime?.length > 1) {
    requestBody.start_time = dateUtil(buyForm.state.effectiveTime[0]).unix()
    requestBody.end_time = dateUtil(buyForm.state.effectiveTime[1]).unix()
  }

  if (computeShowFooterInput.value.showAmountLimitInput) {
    const v = +buyForm.state.amount_limit
    requestBody.amount_limit = Number.isNaN(v) ? 0 : v
  }

  if (computeShowFooterInput.value.showCountLimitInput) {
    const vc = +buyForm.state.count_limit
    requestBody.count_limit = Number.isNaN(vc) ? 0 : Number.parseInt(vc, 10)
  }

  return requestBody
}
// 保存
async function validateForm() {
  const formState = (await buyForm.$form.validate?.().catch(() => checking.setTrue())) as typeof buyForm.state

  if (!formState)
    return false
  const requestBody = formDataToRequestBody()
  if (!requestBody)
    return
  submitForm(requestBody)
}

// 新增、编辑提交
function submitForm(requestBody: ReturnType<typeof formDataToRequestBody>) {
  const isAdd = getIsAddRef.value
  const apiFn = isAdd ? apis.cronInsertAddress : apis.cronUpdateAddress
  btnLoading.value = true

  apiFn({ requestBody })
    .then(() => {
      aMessage.success(
        isAdd ? $t('8-R3wzg1yER4meXqEFA69') : $t('H_oRg1k9VHsY-KndVkwo8'),
      )
      if (isAdd)
        isAble.value = true
      else
        props?.successFunc?.()
    })
    .catch(() => {
      btnLoading.value = false
      submitModalConfirmRef.value?.destroy()
      submitModalConfirmRef.value = null
    })
}
function deleteRowFn() {
  const requestBody = formDataToRequestBody()
  return new Promise((resolve, reject) => {
    apis.cronDeleteAddress({ requestBody })
      .then(() => {
        aMessage.success($t('tO8c22XP7DxAs1BB9yeia'))
        props?.successFunc?.()
        resolve(true)
      })
      .catch((e) => {
        reject(e)
      })
  })
}
// 重置限额
function resetAmountLimitFn() {
  return new Promise((resolve, reject) => {
    const requestBody = formDataToRequestBody()
    if (!requestBody.wallet_address)
      aMessage.error(`${$t('lexKD2K3V3JZO4PUzi9sW')}!`)
      // return reject(false)

    apis.cronResetUsage({ requestBody })
      .then((res) => {
        emits('update:editRow', res)
        props?.successFunc?.()
        aMessage.success($t('eXNQa_Eac1iMhCbLFpqjf'))
        resolve(true)
      })
      .catch((e) => {
        reject(e)
      })
  })
}

const calculationData = computed(() => {
  return useCalculation({
    resourceValue: buyForm.state.order_resource_value,
    rentDuration: rentDurationMap.duration,
    rentTimeUnit: rentDurationMap.unit,
  })
})

// 计算结果
function useCalculation(params: CalculationParams): CalculationResult {
  const calculationData = reactive<CalculationResult>({
    amount: bignumber(0), // 计算结果
    originalPrice: bignumber(0),
    c2cOriginal: bignumber(0),
    c2cDiscount: bignumber(0),
  })
  if (
    !params.resourceValue
    || params.resourceValue === 0
    || params.rentDuration === 0
  )
    return calculationData

  const localComputing = getPayAmountByResource(params)
  const needBurningEnergy = bignumber(params.resourceValue).multipliedBy(420).dividedBy(1000000)
  const duration = params.rentTimeUnit === 'd' ? params.rentDuration : bignumber(1)
  // b2c折扣
  calculationData.amount = localComputing.payAmount ?? bignumber(0)
  calculationData.originalPrice = needBurningEnergy.multipliedBy(duration)

  // c2c 平台最低价
  let price = bignumber(0);
  if (!checkedValues.checkPriceInSun) {
    price = bignumber(buyForm.state.price_in_sun);
  } else {
    price = bignumber(priceRange.c2cMinSun);
  }

  const energy = bignumber(buyForm.state.order_resource_value);
  const day = params.rentTimeUnit === 'h' ? bignumber(1) : bignumber(params.rentDuration);

  calculationData.c2cOriginal = needBurningEnergy.multipliedBy(duration);
  calculationData.c2cDiscount = price.isGreaterThan(0)
    ? energy.multipliedBy(price).dividedBy(1000000).multipliedBy(day)
    : bignumber(0);

  return calculationData;
}
const isC2c = computed(() => buyForm.state.order_type === 2)

const isRecharge = ref(false)
const isAble = ref(false)
const isRest = ref(false)
const restText = ref()
const isDel = ref(false)
// function onSubmit() {
//   window.location.href = `/console/buyer/recharge?lang=${$t('console-lang')}`
// }
// 禁|启用
function enableFn(status: number) {
  const requestBody = formDataToRequestBody()
  if (status === 2) {
    isAble.value = false
    props?.successFunc?.()
  }
  else {
    apis.cronEnable({ requestBody })
      .then(() => {
        props?.successFunc?.()
      })
      .catch((error) => {
        if (error.code === 15027)
          isRecharge.value = true
      })
  }
}
function openRest(type: number) {
  isRest.value = true
  restText.value = type
}
function delFn() {
  isDel.value = true
}
const minCount = computed(() => {
  const { resource_type, order_type } = buyForm.state
  if (resource_type === 1 && order_type === 1)
    return config.value?.order.energy_min_value

  else if (resource_type === 1 && order_type === 2)
    return config.value?.order.energy_c2c_min_value

  else if (resource_type === 0)
    return config.value?.order.bandwidth_c2c_min_value
})
function cancelFn(type: string) {
  if (type === 'del')
    isDel.value = false
  if (type === 'rest')
    isRest.value = false
  if (type === 'able')
    isAble.value = false
  if (type === 'recharge')
    isRecharge.value = false
}
</script>

<template>
  <div class="automatic-popup">
    <div class="flex-between grid text-center justify-stretch">
      <div class="header text-[26px] text-[#e2e8f2] font-semibold mb-[10px]">{{ $t('lqyYiri9qSjxM_7Scgcbp') }}</div>
      <ARadioGroup
        v-model:value="buyForm.state.resource_type"
        class="ant-cover__basic-radio !grid !grid-cols-2"
        buttonStyle="solid"
        :class="{ 'disabled-cls': !getIsAddRef }"
        @change="changeResourceType"
      >
        <ARadioButton class="!h-10 !flex items-center justify-center" :value="1">
          {{ $t("Mqe_hlD_yGPsAMB9oaO1D") }}
        </ARadioButton>
        <ARadioButton class="!h-10 !flex items-center justify-center" :value="0">
          {{ $t("xo7kGD9R2fV50wOIihlhL") }}
        </ARadioButton>
      </ARadioGroup>
    </div>

    <AForm
      class="z-form antd-cover__bubble-help-form bubble-help-top"
      :size="$bp.ltTabletl ? 'small' : undefined"
      layout="vertical"
      v-bind="buyForm.props"
      @keypress.enter="validateForm"
    >
      <!-- 输入框部分 -->
      <div>
        <AFormItem
          class="z-form-item [--bubble-offset:50px] relative"
          :labelCol="{ span: 24 }"
          v-bind="buyForm.itemProps.wallet_address"
          :tooltip="$t('YmYIJsg8LZZO0GzUKJDFf', {
            type1: getResourceTypeTextRef.capital,
            type: getResourceTypeTextRef.lowerCase,
          })"
          :label="$t('mACQqAhr5a2mXJspTSi6N', { type: getResourceTypeTextRef.capital })"
        >
          <AInput
            v-if="getIsAddRef"
            v-model:value="buyForm.state.wallet_address"
            class="custom-input"
            autocomplete="off"
            :placeholder="$t('lexKD2K3V3JZO4PUzi9sW', { type: getResourceTypeTextRef.lowerCase })"
            allowClear
            data-test-id="BAEu"
          >
            <template #clearIcon>
              <i class="prefix-icon i-ant-design:close-circle-outlined" />
            </template>
          </AInput>
          <AInput
            v-else
            class="custom-input"
            :value="desensitization(buyForm.state.wallet_address, 12, -12)"
            disabled
            data-test-id="pFRs"
          />
        </AFormItem>

        <div class="flex-between gap-[20px] lt-tabletl:block">
          <AFormItem
            class="z-form-item flex-1 [--bubble-offset:60px] [--help-min-width:155px]"
            v-bind="buyForm.itemProps.resource_threshold"
            :tooltip="$t('eWrmgR1sYIOFBcnwj-91V', { type: getResourceTypeTextRef.lowerCase })"
            :label="$t('nEyKsdJHTG5asl_Ioi_xn', { type: getResourceTypeTextRef.lowerCase })"
          >
            <AInputNumber
              v-model:value="buyForm.state.resource_threshold"
              class="custom-input"
              autocomplete="off"
              :placeholder="$t('W692s6VNMbeZNfaahszFU', { type: getResourceTypeTextRef.lowerCase })"
              allowClear
              style="width: 100%"
              :precision="0"
            >
              <template #clearIcon>
                <i class="prefix-icon i-ant-design:close-circle-outlined" />
              </template>
            </AInputNumber>
          </AFormItem>

          <AFormItem
            class="z-form-item flex-1 [--bubble-offset:60px]"
            v-bind="buyForm.itemProps.order_resource_value"
            :tooltip="$t('I6ZlFYMeVpQUtjJxkIIDl', { type: getResourceTypeTextRef.lowerCase })"
            :label="$t('Fro8xxiqM9D4vPABEghYB')"
          >
            <AInputNumber
              v-model:value="buyForm.state.order_resource_value"
              class="custom-input"
              autocomplete="off"
              :placeholder="$t('WB8bPzpESFn2Syjva5vOs')"
              allowClear
              style="width: 100%"
              :min="minCount"
              :precision="0"
            >
              <template #clearIcon>
                <i class="prefix-icon i-ant-design:close-circle-outlined" />
              </template>
            </AInputNumber>
          </AFormItem>
        </div>
        <!-- 租用时长 -->
        <AFormItem
          class="mb-2 z-form-item [--bubble-offset:100px]"
          v-bind="buyForm.itemProps.rentDuration"
          :tooltip="$t('1aCZ3IUETmB30-oHaeyrb')"
          :label="$t('W50oU2SCCrT9Xku4IpUpv')"
        >
          <MoreDuration
            v-model:rentDuration="buyForm.state.rentDuration"
            v-model:rentTimeSecond="buyForm.state.rentTimeSecond"
            readonly
            mode="union"
            @onChange="moreDurationChange"
          />
        </AFormItem>
      </div>

      <!-- 设置 -->
      <div class="set-box">
        <div v-if="buyForm.state.resource_type !== 0" class="my-2">
          <span class="mr-8px text-[#e2e8f2]">{{ $t('r2qLhp1Aujeq1CKISumd8') }}:</span>
          <ARadioGroup v-model:value="buyForm.state.order_type" name="radioGroup">
            <ARadio :value="1">{{ $t('4V9yupPwZ839fzI0EXVse') }}</ARadio>
            <ARadio :value="2">{{ $t('0VbBZ-zOs41Z4F5KTHwBb') }}</ARadio>
          </ARadioGroup>
        </div>
        <template v-if="showPriceInSun">
          <ACheckbox
            v-model:checked="checkedValues.checkPriceInSun"
            style="width: 100%;margin-bottom: 12px;"
          >
            <div class="flex-center">
              <span class="text-[#e2e8f2]"> {{ $t('-tjGjrznIq21jSAiXFRvW') }}</span>
              <ATooltip class="z-tooltip" :title="$t('JWh44x9wIPwxlO5EJjtSX')">
                <span><i class="icon question" /></span>
              </ATooltip>
            </div>
            <i class="icon i-ep:arrow-up text-[#8098bf]" :class="checkedValues.checkPriceInSun ? 'flipy' : ''" />
          </ACheckbox>
          <AFormItem
            v-if="!checkedValues.checkPriceInSun"
            class="z-form-item [--bubble-offset:50px] relative"
            v-bind="buyForm.itemProps.price_in_sun"
          >
            <AInputNumber
              v-model:value="buyForm.state.price_in_sun"
              class="custom-input"
              autocomplete="off"
              :placeholder="$t('E5d03GeM98D-zbxLEvHVd')"
              allowClear
              style="width: 100%;"
              :precision="0"
              addonAfter="SUN"
            >
              <template #clearIcon>
                <i
                  class="prefix-icon i-ant-design:close-circle-outlined"
                />
              </template>
            </AInputNumber>
          </AFormItem>
        </template>

        <ACheckbox
          v-model:checked="checkedValues.checkEffectiveTime"
          style="width: 100%;margin-bottom: 12px;"
        >
          <div class="flex-center">
            <span class="text-[#e2e8f2]">{{ $t('TyuEHqgZ1n_4RqAUHWQth') }}</span>
            <ATooltip class="z-tooltip" :title="$t('7RnZIr_jurgylfVT_fplT')">
              <span><i class="icon question" /></span>
            </ATooltip>
          </div>
          <i class="icon i-ep:arrow-up text-[#8098bf]" :class="checkedValues.checkEffectiveTime ? 'flipy' : ''" />
        </ACheckbox>
        <AFormItem
          v-if="!checkedValues.checkEffectiveTime"
          class="z-form-item [--bubble-offset:50px] relative"
          v-bind="buyForm.itemProps.effectiveTime"
        >
          <div ref="$rangePickerContainer" />
          <ARangePicker
            v-model:value="buyForm.state.effectiveTime"
            class="h-10 w-full"
            popupClassName="ant-cover__basic-range-picker-dropdown"
            format="YYYY-MM-DD HH:mm"
            :placeholder="[$t('UlDN8x-MkmeHAn4WVCJ4A'), $t('vbklH8VaC4TnZ9BprPA6_')]"
            :getPopupContainer="() => $rangePickerContainer"
            showTime
            inputReadOnly
            placement="bottomLeft"
            :allowClear="false"
          />
        </AFormItem>
      </div>

      <div class="limit">
        <div class="mb-10px">
          <span class="mr-8px text-[#e2e8f2]">{{ $t('HKqFTwjW3XyQ6mASu6Kf5') }}:</span>
          <ARadioGroup v-model:value="buyForm.state.limit_type" name="radioGroup">
            <ARadio :value="0">{{ $t('WeIbMrwZ00fycppUBnscx') }}</ARadio>
            <ARadio :value="1">{{ $t('fXzY6vhH33p_J8MBGA5h8') }}</ARadio>
            <ARadio :value="2">{{ $t('02yNeWD_dmBlS4g4HkrxE') }}</ARadio>
          </ARadioGroup>
        </div>
        <!-- 金额限制 -->
        <AFormItem
          v-if="computeShowFooterInput.showAmountLimitInput"
          class="z-form-item [--bubble-offset:50px] relative"
          v-bind="buyForm.itemProps.amount_limit"
        >
          <div class="grid grid-cols-[1fr_auto] items-center gap-x-2 lt-mobile:grid-cols-[1fr] lt-mobile:gap-y-2">
            <AInputNumber
              v-model:value="buyForm.state.amount_limit"
              class="custom-input"
              autocomplete="off"
              :placeholder="$t('5WEEv1bTg7FzXKUQdB2zk')"
              allowClear
              style="width: 100%"
              addonAfter="TRX"
              :precision="0"
            />
            <div v-if="!getIsAddRef && editRow?.amount_limit" class="text-[#8098bf]">
              <span class="text-[#8098bf]">
                {{ `${formatAmount(editRow?.amount_used)}/${formatAmount(editRow?.amount_limit)}TRX` }}
              </span>
              <span v-if="editRow?.amount_used" class="text-[#27c1a5] ml-4px cursor-pointer" data-test-id="l7ce" @click="openRest(1)">
                <i class="i-ant-design:redo-outlined mb-3px" /> {{ $t('d7zsfK6OOgTCYGeicxtqs') }}
              </span>
            </div>
          </div>
        </AFormItem>
        <!-- 次数限制 -->
        <AFormItem
          v-if="computeShowFooterInput.showCountLimitInput"
          class="z-form-item [--bubble-offset:50px] relative"
          v-bind="buyForm.itemProps.count_limit"
        >
          <div class="grid grid-cols-[1fr_auto] items-center gap-x-2 lt-mobile:grid-cols-[1fr] lt-mobile:gap-y-2">
            <AInputNumber
              v-model:value="buyForm.state.count_limit"
              class="custom-input"
              autocomplete="off"
              :placeholder="$t('EE2jT9QlgmQ9dTLkVePbx')"
              allowClear
              style="width: 100%"
              :addonAfter="$t('Rl0Xg_qkxfrq3PendyqjL')"
              :precision="0"
            />
            <div v-if="!getIsAddRef && editRow?.count_limit" class="text-[#8098bf] ">
              <span>
                {{ `${formatAmount(editRow?.count_used)}/${formatAmount(editRow?.count_limit) + $t('Rl0Xg_qkxfrq3PendyqjL')}` }}</span>
              <span v-if="editRow?.count_used" class="text-[#27c1a5]  ml-4px cursor-pointer" data-test-id="iswp" @click="openRest(2)">
                <i class="i-ant-design:redo-outlined mb-3px" /> {{ $t('u4MmZS-NBQ_CbC8eToOlf') }}
              </span>
            </div>
          </div>
        </AFormItem>
      </div>
      <!-- 支付金额 -->
      <div v-if="buyForm.state.order_resource_value && buyForm.state.rentTimeUnit">
        <span class="text-[#E2E8F2]">{{ $t('1tmAZrtpkmdGdzbOiSfo') }}：</span>
        <span>
          <span class="text-md line-through"> {{ formatAmount(isC2c ? calculationData.c2cOriginal : calculationData.originalPrice, 6) }}  TRX</span>
          <span class="text-text-green text-2xl ml2 font-bold lt-tabletl:text-15px">{{ formatAmount(isC2c ? calculationData.c2cDiscount : calculationData.amount, 6) }} TRX </span>
        </span>
      </div>
    </AForm>
    <div class="flex gap-20px">
      <AButton ref="target" class="comfirm-btn flex-1" :loading="btnLoading" data-test-id="5t45" @click="validateForm"> {{ $t('cl2g9tLpBp3oKs3jtBlau') }}</AButton>
      <AButton v-if="!getIsAddRef" class="cancel-btn" data-test-id="ign5" @click="delFn">
        <i class="i-local:delete text-18px mr-3px mb-3px" />{{ $t('vPt3xj184Pg2sp9vDzmXa') }}
      </AButton>
    </div>
  </div>
  <ModalContainer
    :isRecharge="isRecharge"
    :isAble="isAble"
    :isRest="isRest"
    :restText="restText"
    :isDel="isDel"
    @onClick="enableFn"
    @delete="deleteRowFn"
    @rest="resetAmountLimitFn"
    @cancel="cancelFn"
  />
</template>

<style lang="scss" scoped>
// 覆盖select
@media bp-lt-tabletl {
  :deep(.ant-select-single:not(.ant-select-customize-input) .ant-select-selector){
    height: 40px;
  }

  :deep(.ant-select-single .ant-select-selector .ant-select-selection-item){
    line-height: 38px;
  }

  :deep(.ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder){
    line-height: 36px;
  }
}

.ant-input-affix-wrapper{
  height: 50px;
  padding: 0 10px;

  @media bp-lt-tabletl {
    height: 40px;
  }
}

.ant-radio-wrapper{
  color: #e2e8f2;
}

:deep(.ant-input-group .ant-input-group-addon){
  background: #13192e;
}

:deep(.ant-picker-range){
  height: 50px;
  background-color: var(--bg-one);
  border-color: var(--bg-one);
  border-radius: 10px;
  box-shadow: none;
}

:deep(.ant-picker){
  .ant-picker-input >input{
    color: #e2e8f2;

    &::placeholder{
      color: #8098bf;
    }
  }

  .ant-picker-clear{
    background: transparent;
  }
}

.ant-picker-status-error.ant-picker,
.ant-picker-status-error.ant-picker:not([disabled]):hover{
  background: transparent;
}

:deep(.anticon svg){
  color: #8098bf;
}

:deep(.ant-checkbox+span){
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.automatic-popup {
  --uno: 'relative w-100%';

  color: var(--text-primary-color);

  .disabled-cls {
    pointer-events: none;
    opacity: 0.6;

    .ant-radio-button-wrapper {
      cursor: not-allowed !important;
    }

    input {
      cursor: not-allowed !important;
      background-color: #f6f6f6;
    }
  }

  .z-form {
    --uno: 'my-22px lt-mobile:text-16px lt-mobile:text-primary lt-mobile:mb-5px';

    .set-box {
      --uno: 'text-16px text-primary';
    }

    .z-form-item {
      --uno: 'mb-16px lt-mobile:mb-10px';
    }
  }

  .prefix-icon{
    font-size: 16px;
    color: #8098bf;
  }
}

.flipy {
  transition: transform 0.3s;
  transform: scaleY(-1);
}

.question{
  --uno: 'text-[#8098bf]';
}

.z-tooltip{
  font-size: 1.2rem;
  color: #8098bf;
}

.custom-input {
  :deep(.ant-input-number-handler-wrap) {
    display: none;
  }
}
</style>
